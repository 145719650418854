<template>
  <div class="d-flex pt-2 pl-2 title">
    <div class="d-flex">
    <span>
      <i class="iconfont icon-vector"/>
    </span>
      <span class="text mx-2">{{ t('monitor.cableLengthChartTitle') }}</span>
    </div>
  </div>
  <div class="background" />
  <Chart class="chart" :data="data"/>
</template>

<script setup>
import Chart from './Chart.vue'
import {useStore} from 'vuex'
import {ref, watch} from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const store = useStore()

const data = ref({})

const updateData = function () {
  // 得到 {年: 长度} 的对象
  let dataObj = {}
  for (let cable of store.state.cables.list) {
    let createdYear = cable.createdat.slice(0, 4)
    if (dataObj[createdYear] === undefined) {
      dataObj[createdYear] = 0
    }
    dataObj[createdYear] += cable.length
  }
  // 排序年份
  let sortedKeys = Object.keys(dataObj).sort()
  // 填充没有新线路的年份
  let start = sortedKeys[0]
  let end = sortedKeys.pop()
  let lapse = parseInt(end) - parseInt(start)
  for (let i = 0; i < lapse; i++) {
    let index = String(parseInt(start) + i)
    if (!dataObj[index]) {
      dataObj[index] = 0
    }
  }
  // 改变形状
  // 计算累计长度
  let dataArray = []
  let accumulated = 0
  let newSortedKeys = Object.keys(dataObj).sort() // 填充后的年份列表
  for (let key of newSortedKeys) {
    dataArray.push((dataObj[key] + accumulated) / 1000)
    accumulated += dataObj[key]
  }
  data.value = {
    tooltip: {
      trigger: 'axis'
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: newSortedKeys,
        axisLine: {
          lineStyle: {
            color: '#cfd3dc',
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: 'white',
          }
        },
        name: t('monitor.cableLengthChartUnit'),
      }
    ],
    series: [
      {
        type: 'line',
        data: dataArray,
      }
    ]
  }
}

watch(() => store.state.cables.list,
    () => updateData())

updateData()


</script>

<style scoped>
.title {
  padding: 16px;
  padding-top: 20px;
  height: 20px;
  border-radius: 5px;
}

.chart {
  position: absolute;
  left: 25px;
  height: 300px;
  width: 380px;
}

.background {
  position: absolute;
  background-color: #18222c;
  opacity: 0.4;
  border-radius: 20px;
  top: 50px;
  left: 20px;
  width: 360px;
  height: 250px;
}
</style>