<template>
  <div class="center">
    <div class="up">
      <div class="bg-color-black item" v-for="item in titleItem" :key="item.title">
        <p class="ml-3 colorBlue fw-b item-title">{{ item.title }}</p>
        <dv-digital-flop class="dv-dig-flop" :config="item.config"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  watch,
  onUnmounted,
} from 'vue'
import {useStore} from 'vuex'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const store = useStore()

const titleItem = ref([])

const updateData = function () {
  // 前四项数据
  let devices = 0
  let onlineDevices = 0
  let offlineDevices = 0
  let alarmingBoxes = 0
  let alarmingCables = 0
  let alarmingDevices = 0
  for (let cable of store.state.cables.list) {
    let cableAlarming = false
    for (let box of cable.gndboxs) {
      if (!box.enable) {
        continue
      }
      let boxAlarming = store.getters['cables/isDeviceAlarm'](box.id)
      if (boxAlarming) {
        alarmingBoxes += 1
        cableAlarming = true
      }
      for (let device of box.subdevs) {
        if (!device.enable) {
          continue
        }
        let deviceModel = store.getters['models/getById'](device.modelId)
        if (!deviceModel.countable) {
          continue
        }
        devices += 1
        let isOnline = store.getters['cables/getDeviceValueById'](device.id).online
        if (isOnline) {
          onlineDevices += 1
        } else {
          offlineDevices += 1
        }

        if (store.getters['cables/isDeviceAlarm'](device.id)) {
          alarmingDevices += 1
        }
      }
    }
    if (cableAlarming) {
      alarmingCables += 1
    }
  }

  // 未处理告警
  let unattended = store.state.cables.alarmsCount.unattended

  setData([
    // {
    //   number: devices,
    //   text: '智能设备数',
    // },
    {
      number: [onlineDevices, devices],
      text: t('monitor.dynamicInfoOnlineDevices')
    },
    {
      number: alarmingDevices,
      text: t('monitor.dynamicInfoWarningDevices')
    },
    {
      number: unattended,
      text: t('monitor.dynamicInfoUnattendedWarnings')
    },
    {
      number: alarmingBoxes,
      text: t('monitor.dynamicInfoAlarmingBoxes')
    },
    {
      number: alarmingCables,
      text: t('monitor.dynamicInfoAlarmingCables')
    },
  ])
}

const setData = function (data) {
  let titleItems = []
  data.map(e => {
    let content = '{nt}'
    let number = [e.number]
    if (typeof e.number === 'object') {
      content = '{nt}/{nt}'
      number = e.number
    }
    titleItems.push({
      title: e.text,
      config: {
        number,
        toFixed: 0,       //  小数点个数
        textAlign: 'center',
        content,
        style: {
          fontSize: 28,
          fill: 'yellow',
        }
      }
    })
  })
  titleItem.value = titleItems
}

const dispatch = function (isRequest) {
  store.dispatch('cables/request', isRequest)
}

watch(
    () => store.state.cables.actionStatus,
    () => {
      updateData()
    },
    {deep: true}
)

onMounted(() => {
  dispatch()
  updateData()
})

</script>

<style lang="scss" scoped>
.center {
  display: flex;
  flex-direction: column;
  background: transparent;

  .up {
    width: 900px;
    height: 100px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;

    .item-title {
      // width: 100px;
      // height: 30px;
    }

    .item {
      border-radius: 10px;
      padding: 8px;
      margin: 8px;
      width: 20%;
      height: 132%;
      // font-size: large;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      p {
        // color: rgb(88, 88, 121) !important;
        text-align: center;
        margin: 0;
      }

      .dv-dig-flop {
        // width: 80px;
        // height: 30px;
      }
    }
  }
}
</style>
