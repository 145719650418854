<template>
  <div className="d-flex pt-2 pl-2 title">
    <div className="d-flex">
    <span>
      <i className="iconfont icon-vector"/>
    </span>
      <span className="text mx-2">{{ t('monitor.deviceAmountChartTitle') }}</span>
    </div>
  </div>
  <Chart class="chart" :data="data"/>
</template>

<script setup>
import Chart from './Chart.vue'
import { useStore } from 'vuex'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const store = useStore()

const data = ref({})

function getData() {
  let dataObj = {}
  for (let cable of store.state.cables.list) {
    if (!cable.gndboxs || cable.gndboxs.length < 1) {
      continue
    }
    for (let box of cable.gndboxs) {
      if (!box.subdevs || box.subdevs.length < 1) {
        continue
      }
      for (let device of box.subdevs) {
        if (!device.enable) {
          continue
        }
        let model = store.getters['models/getById'](device.modelId)
        if (!model.countable) {
          continue
        }
        let createdYear = device.createdat.slice(0, 4)
        if (dataObj[createdYear] === undefined) {
          dataObj[createdYear] = 0
        }
        dataObj[createdYear] += 1
      }
    }
  }
  let sortedKeys = Object.keys(dataObj).sort()
  let start = sortedKeys[0]
  let end = sortedKeys.pop()
  let lapse = parseInt(end) - parseInt(start)
  for (let i = 0; i < lapse; i++) {
    let index = String(parseInt(start) + i)
    if (!dataObj[index]) {
      dataObj[index] = 0
    }
  }
  let dataArray = []
  let accumulated = 0
  let newSortedKeys = Object.keys(dataObj).sort()
  for (let key of newSortedKeys) {
    dataArray.push((dataObj[key] + accumulated))
    accumulated += dataObj[key]
  }
  data.value = {
    tooltip: {
      trigger: 'axis'
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: newSortedKeys,
        axisLine: {
          lineStyle: {
            color: '#cfd3dc',
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: 'white',
          }
        },
        name: t('monitor.deviceAmountChartUnit'),
      }
    ],
    series: [
      {
        type: 'line',
        data: dataArray,
      }
    ]
  }
}

getData()

watch(() => store.state.cables.list,
    () => getData()
)

</script>

<style scoped>
.title {
  padding: 16px;
  padding-top: 20px;
  height: 20px;
  border-radius: 5px;
}

.chart {
  position: relative;
  left: 25px;
  height: 300px;
  width: 380px;
}
</style>