<template>
  <div ref="chartRef" class="chart"></div>
</template>

<script setup>
import {init} from 'echarts'
import {
  ref,
  onMounted,
  defineProps,
  watch,
} from 'vue'

const props = defineProps(['data'])

const chartRef = ref()
let chart = undefined

onMounted(() => {
  chart = init(chartRef.value)
  setOption()
})

const setOption = function () {
  if (chart) {
    chart.setOption(props.data, {notMerge: true})
  }
}

watch(
    () => props.data,
    (val) => setOption(),
)

</script>

<style scoped>
.chart {
  width: 300px;
  height: 300px;
}
</style>