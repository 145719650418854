<template>
  <div class="d-flex pt-2 pl-2 title">
    <div class="d-flex">
    <span>
      <i class="iconfont icon-vector"/>
    </span>
      <span class="text mx-2">{{ t('monitor.warningDataChartTitle') }}</span>
    </div>
  </div>
  <Chart class="chart" :data="data"/>
</template>

<script setup>
import Chart from './Chart.vue'
import {useStore} from 'vuex'
import {ref, watch} from 'vue'
import {formatTimeToStr} from "@/utils/date";
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

function getYear(t) {
  if (t === undefined) {
    t = Date.now()
  }
  return formatTimeToStr(t, 'yyyy')
}

function getMonth(t) {
  if (t === undefined) {
    t = Date.now()
  }
  return formatTimeToStr(t, 'MM')
}

const store = useStore()

const data = ref({})

const url = undefined // TODO

const updateData = function () {
  let repaired = 0,
      confirmed = 0,
      unattended = 0
  if (!store.state.cables.deviceAlarms.list) {
    return
  }
  for (let al of store.state.cables.deviceAlarms.list) {
    if (al.state === 1) {
      unattended += 1
    } else if (al.state === 2) {
      confirmed += 1
    } else if (al.state === 3) {
      if (
          getYear(al.repairedat) === getYear() &&
          getMonth(al.repairedat) === getMonth()
      ) {
        repaired += 1
      }
    }
  }

  data.value = {
    series: [
      {
        type: 'pie',
        radius: '50%',
        data: [
          {name: t('monitor.warningRepaired'), value: repaired},
          {name: t('monitor.warningUnattended'), value: unattended},
          {name: t('monitor.warningConfirmed'), value: confirmed},
        ],
        label: {
          formatter: '{b}：{c}',
          overflow: 'break',
          textBorderWidth: '0px',
          color: '#cfd3dc',
        }
      }
    ]
  }
}

updateData()

watch(
    () => store.state.cables.actionStatus,
    () => {
      updateData()
    },
    {deep: true}
)

</script>

<style scoped>
.title {
  padding: 16px;
  padding-top: 20px;
  height: 20px;
  border-radius: 5px;
}

.chart {
  position: relative;
  top: -20px;
  left: 10px;
  height: 300px;
  width: 350px;
}
</style>