<template>
  <span class="cable-name">{{ getCableName(cable) }}</span>
  <!--  <div class="scroll-graph-wrapper">-->
  <div>
    <!--    <div class="float-button" @click="moveLeft">-->
    <!--      <el-icon size="large">-->
    <!--        <ArrowLeft/>-->
    <!--      </el-icon>-->
    <!--    </div>-->
    <!--    <el-scrollbar :disabled="!props.useMonitorCenterStyle" @mousedown="drag" @mouseup="drag">-->
    <div class="box-graph box-graph-scroll" ref="scrollbarRef" @mousedown="drag" @mouseup="drag">
<!--      <box-graph v-if="boxes.length > 0" :box="boxes[0]" :leftmost="true"/>-->
<!--      <div v-for="box in boxes.slice(1, boxes.length-2)" :key="box.id">-->
<!--        <box-graph :box="box"/>-->
<!--      </div>-->
<!--      <box-graph v-if="boxes.length > 1" :box="boxes[boxes.length-1]" :rightmost="true"/>-->
      <div v-for="box in boxes" :key="box.id">
        <box-graph :box="box"/>
      </div>
    </div>
    <!--    </el-scrollbar>-->
    <!--    <div class="float-button" @click="moveRight">-->
    <!--      <el-icon size="large">-->
    <!--        <ArrowRight/>-->
    <!--      </el-icon>-->
    <!--    </div>-->
  </div>
</template>

<script setup>
import {ref, defineProps, computed} from "vue"
import {ArrowLeft, ArrowRight} from "@element-plus/icons"
import BoxGraph from "./BoxGraph.vue"
import {getCableName} from '@/utils/cableNameGen'
import {useStore} from "vuex"

const store = useStore()

const props = defineProps({
      // data: {
      //   type: Object,
      //   default: () => {
      //     return {}
      //   },
      // },
      id: {
        type: Number,
      },
      // useMonitorCenterStyle: {
      //   type: Boolean,
      //   default: false,
      // },
    }
)

const cable = computed(() => {
  return store.getters["cables/getById"](props.id)
})

const boxes = computed(() => {
  // if (!cable.value?.gndboxs) {
  //   return
  // }
  // return [...cable.value.gndboxs].filter(x => x.enable).sort((a, b) => {
  //   return a.queue - b.queue
  // })
  return getBoxes()
})

function getBoxes() {
  if (!cable.value?.gndboxs) {
    return []
  }

  let sorted = [...cable.value.gndboxs].filter(x => x.enable).sort((a, b) => {
    return a.queue - b.queue
  })

  let r = []
  let len = sorted.length
  for (let i = 0; i < sorted.length; i++) {
    let box = sorted[i]
    let boxInfo = {id: box.id}
    // first box
    if (i === 0 && box.modelId !== 7) {
      boxInfo.leftmost = true
    }

    // last box
    if (i === len - 1 && box.modelId !== 7) {
      boxInfo.rightmost = true
    }

    // 判断 conjunct
    let nextBox = sorted[i + 1]
    if (isConjuncted(box, nextBox)) {
      boxInfo.leftConjunct = true
    }

    let prevBox = sorted[i - 1]
    if (isConjuncted(prevBox, box)) {
      boxInfo.rightConjunct = true
    }

    r.push(boxInfo)
  }
  return r
}

function isConjuncted(box, nextBox) {
  return box && nextBox && box.conjunct && nextBox.conjunct && box.conjunct === nextBox.deviceCode && nextBox.conjunct === box.deviceCode
}


const scrollbarRef = ref()

function moveRight() {
  scrollbarRef.value.scrollLeft += 200
}

function moveLeft() {
  scrollbarRef.value.scrollLeft -= 200
}

let dragStartX = 0

function drag(e) {
  if (e.type === 'mousedown') {
    dragStartX = e.screenX
  } else if (e.type === 'mouseup') {
    scrollbarRef.value.scrollLeft -= e.screenX - dragStartX
  }
}

</script>

<style scoped>
.box-graph {
  display: flex;
  flex-direction: row;
}

.box-graph-scroll {
  overflow-x: auto;
  height: 240px;
}

.box-graph::-webkit-scrollbar-track {
  border: 1px solid #000;
  padding: 2px 0;
  background-color: #404040;
}

.box-graph::-webkit-scrollbar {
  width: 10px;
}

.box-graph::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #737272;
  border: 1px solid #000;
}

.float-button {
  width: 20px;
  height: 180px;
  top: 90px;
  line-height: 170px;
  /*position: static;*/
  /*top: 90px;*/
}

.float-button:hover {
  background-color: ghostwhite;
  color: black;
  opacity: 0.5;
}

.scroll-graph-wrapper {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*align-items: flex-start;*/
  /*margin-bottom: 10px;*/
  /*height: 300px;*/
}

.cable-name {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}

.box-graph-wrap {
  flex-wrap: wrap;
}

</style>
