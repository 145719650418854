<template>
  <div class="d-flex pt-2 pl-2 title">
    <div class="d-flex">
    <span>
      <i class="iconfont icon-vector"/>
    </span>
      <span class="text mx-2">{{ t('monitor.cableAgeChartTitle') }}</span>
    </div>
  </div>
  <div class="background" />
  <Chart class="chart" :data="data"/>
</template>

<script setup>
import Chart from './Chart.vue'
import {useStore} from 'vuex'
import {ref, watch} from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const store = useStore()

const data = ref({})

const updateData = function () {
  let dataObj = {}
  const now = new Date()
  for (let cable of store.state.cables.list) {
    let created = new Date(cable.createdat)
    let elapsedDays = (now - created) / 1000 / 3600 / 24
    let label = ''
    if (elapsedDays < 365) {
      label = t('monitor.cableAgeChartLessThanOneYear')
    } else {
      label = Math.floor(elapsedDays / 365) + t('monitor.cableAgeChartYear')
    }
    if (dataObj[label] === undefined) {
      dataObj[label] = 0
    }
    dataObj[label] += 1
  }
  let dataArray = []
  for (let name in dataObj) {
    dataArray.push({name, value: dataObj[name]})
  }
  data.value = {
    series: [
      {
        type: 'pie',
        radius: '50%',
        data: dataArray,
        label: {
          formatter: '{b}: {c}' + t('monitor.cableAgeChartCableUnit'),
          overflow: 'break',
          textBorderWidth: '0px',
          color: '#cfd3dc',
        }
      }
    ]
  }
}

watch(() => store.state.cables.list,
    () => updateData())

updateData()


</script>

<style scoped>
.title {
  padding: 16px;
  padding-top: 20px;
  height: 20px;
  border-radius: 5px;
}

.chart {
  position: relative;
  top: -20px;
  left: 10px;
  height: 300px;
  width: 350px;
}

.background {
  position: absolute;
  background-color: #1d3043;
  opacity: 0.8;
  border-radius: 20px;
  top: 50px;
  left: 20px;
  width: 360px;
  height: 260px;
}
</style>