<template>
  <div class="d-flex pt-2 pl-2 title cable-info">
    <div class="d-flex">
    <span>
      <i class="iconfont icon-vector"/>
    </span>
      <span class="text mx-2">{{ t('monitor.cableInfoTitle') }}</span>
    </div>
  </div>
  <div class="centerRight2">
    <div class="staticBox bg-color-black">
      <div class="box" v-for="item in flops" :key="item.title">
        <div class="flopTitle">{{ item.title }}</div>
        <dv-digital-flop :config="item.config"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  watch,
} from 'vue'
import {useStore} from 'vuex'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const store = useStore()

const flops = ref([])

function getLenFromBoxes(boxes) {
  let length = 0
  let boxLen = []
  let maxQueue = -1
  let endOfCable = 0
  for (let [i, box] of boxes.entries()) {
    boxLen.push(box.length)
    if (box.queue > maxQueue) {
      maxQueue = box.queue
      endOfCable = i
    }
  }
  for (let [ind, l] of boxLen.entries()) {
    if (ind === endOfCable) {
      continue
    }
    length += l
  }
  return length
}

const updateData = function () {
  let length = 0
  let numCables = 0
  let numBoxes = 0
  let numLines = 0
  for (let cable of store.state.cables.list) {
    numCables += 1
    // numBoxes += cable.gndboxs.length
    for (let b of cable.gndboxs) {
      if (b.enable) {
        numBoxes += 1
      }
    }
    numLines += (cable.gndboxs.length - 1)
    // 根据接地箱间距计算线路长度
    // length += getLenFromBoxes(cable.gndboxs)
    // 直接获取线路长度
    length += cable.length
  }
  flops.value = [
    {
      title: t('monitor.cableTotalLength'),
      config: {
        number: [length/1000],
        toFixed: 2,
        content: `{nt}${t('monitor.cableTotalLengthUnit')}`
      },
    },
    {
      title: t('monitor.cableCount'),
      config: {
        number: [numCables],
        content: `{nt}${t('monitor.cableCountUnit')}`
      },
    },
    {
      title: t('monitor.boxCount'),
      config: {
        number: [numBoxes],
        content: `{nt}${t('monitor.boxCountUnit')}`
      },
    },

    {
      title: t('monitor.cableSegmentCount'),
      config: {
        number: [numLines],
        content: `{nt}${t('monitor.cableSegmentCountUnit')}`
      },
    },
  ]
}

onMounted(() => {
  updateData()
})

watch(
    () => store.state.cables.actionStatus.all,
    (v) => {
      if (v > 1) {
        updateData()
      }
    }
)

</script>


<style lang="scss" scoped>
.centerRight2 {
  padding: 10px 16px 20px 16px;
  border-radius: 5px;

  .bg-color-black {
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .body-box {
    border-radius: 10px;

    .dv-scr-board {
      width: 270px;
      height: 340px;
    }
  }
}

.staticBox {
  display: flex;
  flex-wrap: wrap;
  height: 227px;
}

.box {
  width: 180px;
  height: 115px;
  //flex-grow: 1;
}

.flopTitle {
  position: relative;
  top: 5px;
  left: 10px;
}

.cable-info {
  position: relative;
  padding-top: 20px;
  margin-left: 8px;
}

</style>
