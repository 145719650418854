<template>
  <div class="d-flex pt-2 pl-2 title">
    <div class="d-flex">
    <span>
      <i class="iconfont icon-vector"/>
    </span>
      <span class="text mx-2">{{ t('monitor.deviceAgeChartTitle') }}</span>
    </div>
  </div>
  <Chart class="chart" :data="data"/>
</template>

<script setup>
import Chart from './Chart.vue'
import {useStore} from 'vuex'
import {ref, watch} from 'vue'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const store = useStore()

const data = ref({})

function getData() {
  let dataObj = {}
  const now = new Date()
  for (let cable of store.state.cables.list) {
    if (!cable.gndboxs || cable.gndboxs.length < 1) {
      continue
    }
    for (let box of cable.gndboxs) {
      if (!box.subdevs || box.subdevs.length < 1) {
        continue
      }
      for (let device of box.subdevs) {
        if (!device.enable) {
          continue
        }
        let model = store.getters['models/getById'](device.modelId)
        if (!model.countable) {
          continue
        }
        let created = new Date(device.createdat)
        let elapsedDays = (now - created) / 1000 / 3600 / 24
        let label = ''
        if (elapsedDays < 365) {
          label = t('monitor.deviceAgeChartLessThanOneYear')
        } else {
          label = Math.floor(elapsedDays / 365) + t('monitor.deviceAgeChartYear')
        }
        if (dataObj[label] === undefined) {
          dataObj[label] = 0
        }
        dataObj[label] += 1
      }
    }
  }
  let dataArray = []
  for (let name in dataObj) {
    dataArray.push({name, value: dataObj[name]})
  }
  data.value = {
    series: [
      {
        type: 'pie',
        radius: '50%',
        data: dataArray,
        label: {
          formatter: '{b}: {c}' + t('monitor.deviceAgeChartDeviceUnit'),
          overflow: 'break',
          textBorderWidth: '0px',
          color: '#cfd3dc',
        }
      }
    ]
  }
}

getData()

watch(() => store.state.cables.list,
    () => getData())

</script>

<style scoped>
.title {
  padding: 16px;
  padding-top: 20px;
  height: 20px;
  border-radius: 5px;
}

.chart {
  position: relative;
  top: -20px;
  left: 10px;
  height: 300px;
  width: 350px;
}
</style>