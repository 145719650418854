<template>
  <div class="content-box3">
    <el-row justify="space-between">
      <el-col :span="5" class="left-box">
        <div>
          <dv-border-box-13>
            <StaticInfoBox />
          </dv-border-box-13>
        </div>
        <div>
          <dv-border-box-13 class="cableLength">
            <CableLengthChart />
          </dv-border-box-13>
        </div>
        <div>
          <dv-border-box-13 class="cableAge">
            <CableAgeChart />
          </dv-border-box-13>
        </div>

      </el-col>
      <el-col :span="14" class="middle-box">
        <div v-if="store.state.user?.userInfo?.nonMapMode" style="height: 950px">
          <cable-list />
        </div>
        <div v-else>
          <DynamicInfoBox />
          <div class="map">
            <map-cables />
          </div>
        </div>
      </el-col>
      <el-col :span="5" class="right-box">
        <div>
          <dv-border-box-13 class="warningData">
            <WarningDataChart />
          </dv-border-box-13>
        </div>
        <div>
          <dv-border-box-13 class="accessoryAge">
            <DeviceAgeChart />
          </dv-border-box-13>
        </div>
        <div>
          <dv-border-box-13 class="accessoryAmount">
            <DeviceAmountChart />
          </dv-border-box-13>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import DynamicInfoBox from "./monitorcenter/DynamicInfoBox.vue"
import MapCables from "./components/mapCables/index.vue"
import CableLengthChart from './monitorcenter/CableLengthChart.vue'
import CableAgeChart from './monitorcenter/CableAgeChart.vue'
import DeviceAgeChart from './monitorcenter/DeviceAgeChart.vue'
import DeviceAmountChart from './monitorcenter/DeviceAmountChart.vue'
import StaticInfoBox from "./monitorcenter/StaticInfoBox.vue"
import WarningDataChart from "./monitorcenter/WarningDataChart.vue"

import CableList from "./monitorcenter/CableList.vue"

import {useStore} from "vuex"
const store = useStore()
</script>

<style>
.cableAge {
  height: 328px;
}

.cableLength {
  height: 323px;
}

.warningData {
  height: 310px;
}

.accessoryAge {
  height: 310px;
}

.accessoryAmount {
  height: 330px;
}

</style>
