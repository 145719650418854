<template>
  <dv-border-box-10>
    <el-scrollbar>
      <div class="wrapper">
        <div v-for="l in store.state.cables.list" :key="l.id">
          <CableGraph :id="l.id" useMonitorCenterStyle />
        </div>
      </div>
    </el-scrollbar>
  </dv-border-box-10>
</template>

<script setup>
import CableGraph from "@/components/cableGraph/CableGraphScroll.vue"
import {useStore} from "vuex"
const store = useStore()
</script>

<style scoped>
.wrapper {
  height: 900px;
  padding: 20px;
}
</style>
